<!--
 * @Descripttion: 404页面
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-10 02:12:20
 * @LastEditTime: 2021-08-10 02:13:17
-->
<template>
    <div>
        <img src="https://gitee.com/static/errors/images/404.png"/>
    </div>
</template>

<script>
export default {
    name: 'Dbgameweb404',

 

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>